<template>
    <div class="page-wrapper">
        <section class="hero_block">
            <div class="data">
                <h1 class="title" data-inview="fadeInUp" data-delay="200">
                    <span v-html="contentIsLoaded ? content.heroSections.titre : ''"></span>
                </h1>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="contentIsLoaded ? content.heroSections.texte : ''"></span>
                </p>
                <a :href="contentIsLoaded ? content.heroSections.boutonUrl[$route.meta.lang] : ''" class="cta regular-text" data-inview="fadeInUp" data-delay="400"
                    ><span v-html="contentIsLoaded ? content.heroSections.bouton.customText : ''"></span></a
                >
                <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
            </div>
            <div class="hero_img" data-inview="slideLeft" data-delay="200">
                <img :src="contentIsLoaded ? content.heroSections.image.url : ''" :alt="contentIsLoaded ? content.heroSections.image.titre : ''" />
            </div>
            <img src="@/assets/img/birds-hero.svg" alt="birds" class="birds_hero" />
        </section>

        <section class="text_block">
            <h2 class="title medium" data-inview="fadeInUp" data-delay="200">
                <span v-html="contentIsLoaded ? content.entete1.titre : ''"></span>
            </h2>
            <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                <span v-html="contentIsLoaded ? content.entete1.texte : ''"></span>
            </p>
            <a :href="contentIsLoaded ? content.heroSections.boutonUrl[$route.meta.lang] : ''" class="cta regular-text" data-inview="fadeInUp" data-delay="400"
                ><span v-html="contentIsLoaded ? content.heroSections.bouton.customText : ''"></span></a
            >
        </section>

        <section class="approche_wrap" v-if="pageIsLoaded">
            <h2 class="title medium" data-inview="fadeInUp" data-delay="200">
                {{ content.titre1 }}
            </h2>
            <div class="bloc" data-inview="fadeInUp" :data-delay="(2 + i) + '00'" v-for="(item, i) in content.cartes1" :key="i">
                <div class="line"></div>
                <p class="regular-text">
                    <span v-html="item.texte"></span>
                </p>
            </div>
            <img src="@/assets/img/texture-2.svg" alt="texture" class="texture" />
        </section>

        <section class="historique_wrap" v-if="pageIsLoaded">
            <div class="title_wrap">
                <h2 class="title medium">{{ content.titre2 }}</h2>
                <nav>
                    <a
                        href="#"
                        class="title small"
                        :class="{ active: maison }"
                        @click.prevent="maisonh"
                        >Pavillon Pierre-Péladeau</a
                    >
                    <a
                        href="#"
                        class="title small"
                        :class="{ active: !maison }"
                        @click.prevent="maisonf"
                        >Maison Raymonde-Chopin-Péladeau</a
                    >
                </nav>
            </div>

            <div class="content" :class="{ show: maison }" data-inview="fadeInUp" data-delay="200">
                <div class="bloc">
                    <h3 class="title small">{{ content.entete2.titre }}</h3>
                    <p class="regular-text">
                        <span v-html="content.entete2.texte"></span>
                    </p>
                </div>
                <div class="bloc">
                    <h3 class="title small">{{ content.entete3.titre }}</h3>
                    <p class="regular-text">
                        <span v-html="content.entete3.texte"></span>
                    </p>
                </div>
                <div class="photos">
                    <h3 class="title small">{{ content.titre3 }}</h3>
                    <div class="photo" v-for="(item, i) in content.equipe1" :key="i">
                        <div class="img"><img :src="item.image.url" :alt="item.image.titre" /></div>
                        <p class="regular-text name">{{ item.titre }}</p>
                        <p class="regular-text">{{ item.texte }}</p>
                    </div>
                </div>
                <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
            </div>

            <div class="content" :class="{ show: !maison }" data-inview="fadeInUp" data-delay="200">
                <div class="bloc">
                    <h3 class="title small">{{ content.entete4.titre }}</h3>
                    <p class="regular-text">
                        <span v-html="content.entete4.texte"></span>
                    </p>
                </div>
                <div class="bloc">
                    <h3 class="title small">{{ content.entete5.titre }}</h3>
                    <p class="regular-text">
                        <span v-html="content.entete5.texte"></span>
                    </p>
                </div>
                <div class="photos">
                    <h3 class="title small">{{ content.titre5 }}</h3>
                    <div class="photo" v-for="(item, i) in content.equipe2" :key="i">
                        <div class="img"><img :src="item.image.url" :alt="item.image.titre" /></div>
                        <p class="regular-text name">{{ item.titre }}</p>
                        <p class="regular-text">{{ item.texte }}</p>
                    </div>
                </div>
                <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
            </div>
        </section>

        <section class="goals_wrap" v-if="pageIsLoaded">
            <div class="content">
                <h2 class="title medium" data-inview="fadeInUp" data-delay="200">
                    Nos partenaires
                </h2>
                <p class="regular-text max_width" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.texte"></span>
                </p>
                <div class="goals">
                    <h3 class="title small" data-inview="fadeIn" data-delay="200">
                        Parmi les nombreux partenaires financiers et professionnelle, nous comptons :
                    </h3>
                    <div class="partenaires-logos extend goal" data-inview="fadeInUp" :data-delay="(2 + i) + '00'" v-for="(item, i) in content.thumbs1" :key="i">
                        <div class="img">
                            <img :src="item.image.url" :alt="item.image.titre" />
                        </div>
                        <p class="regular-text">{{ item.texte }}</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'
// import Flickity from 'flickity'

export default {
    name: 'Apropos',

    data() {
        return {
            // teamCarousel: null,
            maison: true,
        }
    },

    methods: {
        maisonh() {
            this.maison = true
        },
        maisonf() {
            this.maison = false
        },
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.hero_block')) {
                return
            }

            if (st >= 75) {
                document.querySelector('.hero_block').classList.add('remove_margin_top')
            } else {
                document.querySelector('.hero_block').classList.remove('remove_margin_top')
            }
        },
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
                        //     wrapAround: true,
                        //     imagesLoaded: true,
                        //     prevNextButtons: true,
                        //     pageDots: false,
                        // })

                        this.toogleNavBarStatus()
                        window.addEventListener('scroll', () => {
                            this.toogleNavBarStatus()
                        })
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
